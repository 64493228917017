.home-banner {
    height: 60vh;
    background: url('https://images.unsplash.com/photo-1508873699372-7aeab60b44ab?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position-y: bottom;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 10vw;
}
.home-banner-content {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 4px;

}
.service-card {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin: 10px;
    padding: 10px;
    height: 160px;
}
.service-card h4 {
    font-weight: bold;
    font-size: 18px;
}
.service-card p {
    font-size: 14px;
    letter-spacing: 0.1px;
}
.service-card:hover h4 {
    color: #165888;
}
@media (max-width: 500px) {
    .home-banner {
        padding: 5vw;
        height: 350px;
    }
}