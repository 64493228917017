.logo-block {
    padding: 8vh 0 2vh;
}
.reach-header {
    background-color: #fff;
    /* height: 30vh; */
    padding: 40px 20px 0px;
}
.text-theme {
    color: #165888;
}
.logo-txt {
    font-size: 18px;
    margin: 0;
}
.logo-text2 {
    font-weight: bold;
    color: #8a8989;
}
h1 {
    font-size: 20px;
}
.credentials {
    font-size: 16px;
    color: #8a8989;
}
.info-block {
    margin-bottom: 10px;
}
.info-block label {
    color: #666;
}
.info-block p {
    color: #000;
    font-weight: bold;
}