body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-theme {
  /* color: #145886; */
  color: #0055A5;
}
.bg-theme {
  background-color: #145886;
}
a {
  text-decoration: none!important;
}
/* Font weight classes from f-100 to f-bold */
.f-100 {
  font-weight: 100;
}

.f-200 {
  font-weight: 200;
}

.f-300 {
  font-weight: 300;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}

.f-bold {
  font-weight: 700;
}
.page-header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-header {
  background: url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position-y: center;
  color: #fff;
}
.btn-primary {
  background: #165888!important;
  background-color: #165888!important;
}

